.wishlist {
    width: 100%;
    display: inline-block;
}

.wishlist .Tabs {
    width: 100%;
    display: inline-block;
    background: #ddd;
}

.Tabs ul {
    display: inline-block;
}

.Tabs ul li {
    padding: 20px 30px;
    font-size: 24px;
    display: inline-block;
    color: #333;
    font-weight: 700;
    cursor: pointer;
}

.Tabs ul li:first-child {
    background: #fff;
}

.shoppingList {
    width: 100%;
    display: inline-block;
}

.shoppingList .print {
    width: auto;
    padding: 10px 0;
    border-bottom: 2px solid #ddd;
}

.shoppingList .print .shopping {
    width: 100%;
    display: flex;
    align-items: center;
}

.shoppingList .print .shopping h3 {
    color: #333;
    font-size: 32px;
    font-weight: 700;
    margin: 0;
}

.shoppingList .print .shopping li {
    display: inline-block;
    padding: 6px 0 0 8px;
}

i.fa {
    font-size: 24px;
}

.shoppingList  .share {
    width: auto;
    text-align: end;
}

.shoppingList .share li {
    display: inline-block;
    padding: 10px;
}

.shoppingList .share li:first-child {
    border-right: 1px solid #dddd;
}

::placeholder {
    color: #999;
    font-size: 18px;
}

input:focus {
    border: none;
}

.shoppingList .print {
    width: auto;
    padding: 16px 0;
    border-bottom: 2px solid #ddd;
}

.shoppingList .filter {
    width: auto;
    padding: 16px 0;
}

.shoppingList .filter .searchItem {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.shoppingList .filter .list {
    text-align: start;
    width: 100%;
}

.shoppingList .filter .list li {
    margin: 0 10px;
    cursor: pointer;

}

.shoppingList .filter .list li:first-child {
    color: #999;
}

.shoppingList .filter #filter {
    width: 80%;
    border: 1px solid #ddd;
    padding: 10px 8px;
}

.shoppingList .filter .searchItem input {
    border: 1px solid #ddd;
    width: 100%;
    padding: 10px 16px;
}

.shoppingList .filter .searchItem i.fa.fa-search {
    position: absolute;
    right: 14px;
    color: #666;
    cursor: pointer;
}

.shoppingList .filter .select {
    width: 100%;
    text-align: end;
}


/* listview css */
.list-view {
    width: 100%;
    display: inline-block;
}

.list-view .product {
    width: 100%;
}

.list-view .product .product-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.list-view .product .product-box figure {
    width: 200px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ddd;
    padding: 10px;
    height: 200px;
    background-color: #ddd;
}

.list-view .product .product-box .title {
    width: 20%;
    display: inline-block;
    margin-left: 20px;
}

.list-view .product .product-box .title h4 {
    color: #000;
    font-size: 26px;
}

.list-view .product .product-box .title h6 {
    font-size: 18px;
}

.list-view .product .product-box .title span {
    font-size: 18px;
    color: #666;
}

.list-view .product .product-box .buttons {
    width: 70%;
    text-align: end;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: end;
}

.list-view .product .product-box .buttons button {
    width: 200px;
    height: 50px;
    border: none;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    background: #FF9407;
}

.list-view .product .product-box .buttons button:last-child {
    background: red;
}


/* grid view css */
.grid-view {
    width: 100%;
    display: inline-block;
}

.grid-view .product {
    width: 100%;
}

.grid-view .product .product-cart {
    width:100%;
    max-width:400px;
    padding-bottom: 30px;
    margin: 20px 0;
    box-shadow: 3px 11px 14px #ddd;
    border-radius: 5px;
}

.grid-view .product .product-cart figure {
    width: 100%;
    text-align: center;
    height: 220px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
}

.grid-view .product .product-cart .title {
    width: auto;
    margin-left: 20px;
}

.grid-view .product .product-cart .title h4 {
    font-size: 20px;
    margin: 20px 0;
}

.grid-view .product .product-cart .title h6 {
    font-size: 18px;
    margin-bottom: 30px;
}

.grid-view .product .product-cart .buttons {
    text-align: center;
    width: auto;
}

.grid-view .product .product-cart .buttons button {
    width: 136px;
    margin: 0 22px;
    border: none;
    background: #FF9407;
    height: 46px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.grid-view .product .product-cart .buttons button:last-child {
    background: red;
}

